:root{
  --secondary-color:#afe4e6;
  --secondary-color-2:#ddfbfca9;
  --secondary-color-3:#9ad6cb;
  --secondary-color-4:#8be7f5;
  --btn-color:#6e086e;
  --btn-color-2:#0808d8;
  --scroll-bar-color:#990899;
  --transparent-color-01:#00000033;
  --transparent-color-02:#000000b3;
  --white-color-01:#ffffff;
  --white-color-02:#ffffff02;
  --white-color-1:rgba(255, 255, 255, 0.7);
  --white-color-2:rgba(255, 255, 255, 0.3);
  --cornsilk-color:#fff8dc;
  --grey-5:#353434;
  --grey-2:#808080;
  --transiton: all .5s ease-in-out;
}




body {
  margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face { */
  /* font-family: "PoppinsBold"; */
  /* src: local("PoppinsBold"), */
   /* url("./components/Fonts/Poppins-Bold.ttf") format("truetype"); */
   /* font-weight: bold; */
/* } */

