*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:focus {
  outline: none;
}

.header {
  width: 100%;
  height: 6.3rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  list-style: none;
  background: linear-gradient(to right, var(--secondary-color-2), var(--secondary-color));
  text-transform: uppercase;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 1px 0 5px var(--grey-2), 1px 1px 5px 1px var(--grey-2);
}
.header h1 {
  font-size: 1.3rem;
  margin-left: 2.5rem;
}
.header .nav-bar {
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  background-color: var(--btn-color);
  color: var(--white-color-01);
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 50%;
  display: none;
  transform: translateY(-50%);
}
.header .nav-bar .bugger {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease-in-out;
}
.header .nav-bar .bugger::before {
  content: "";
  top: -300%;
  width: 2rem;
  height: 0.2rem;
  position: absolute;
  background-color: var(--white-color-01);
  transform: rotate(0deg);
}
.header .nav-bar .bugger::after {
  content: "";
  width: 2rem;
  height: 0.2rem;
  top: 300%;
  position: absolute;
  transition: transform 0.5s ease-in-out;
  transform: rotate(0deg);
  background-color: var(--white-color-01);
}
.header .nav-bar .bugger div {
  width: 2rem;
  height: 0.2rem;
  background-color: var(--white-color-01);
  transition: transform 0.5s ease-in-out;
}
.header .nav-bar-close {
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  background-color: var(--btn-color);
  color: var(--white-color-01);
  cursor: pointer;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.5s ease-in-out;
}
.header .nav-bar-close .bugger {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease-in-out;
}
.header .nav-bar-close .bugger::before {
  content: "";
  top: 15%;
  width: 2rem;
  height: 0.2rem;
  position: absolute;
  transform: rotate(45deg);
  transition: transform 0.5s ease-in-out;
  background-color: var(--white-color-01);
}
.header .nav-bar-close .bugger::after {
  content: "";
  width: 2rem;
  height: 0.2rem;
  top: 300%;
  position: absolute;
  transition: transform 0.5s ease-in-out;
  transform: rotate(125deg);
  background-color: var(--white-color-01);
}
.header .nav-bar-close .bugger .center {
  width: 2rem;
  height: 0.2rem;
  background-color: var(--white-color-01);
  display: none;
  transition: transform 0.5s ease-in;
}
.header .nav-body {
  width: 100%;
  display: flex;
  transition: transform 0.5s ease-in;
}

.nav {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkCont {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  gap: 2rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.linkCont li {
  cursor: pointer;
  position: relative;
  display: block;
  padding: 0.5rem 0;
  white-space: nowrap;
}
.linkCont li:hover::after, .linkCont li :focus::after {
  width: 100%;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.linkCont li::after, :focus::after {
  content: "";
  position: absolute;
  width: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  background-color: var(--btn-color);
  height: 0.15rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.text-deco {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  width: 100%;
  display: block;
  transition: all 0.5s ease-in-out;
}

.btn-cont {
  margin-right: auto;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  text-transform: capitalize;
  font-weight: 600;
}

.red {
  min-height: 100vh;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 1rem;
  border: 2px inset var(--transparent-color-01);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.2rem;
  background-color: var(--secondary-color);
}

::-webkit-scrollbar-track {
  -webkit-margin-before: 6.3rem;
          margin-block-start: 6.3rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: var(--white-color-01);
  box-shadow: inset 0 0 6px var(--transparent-color-02);
  -webkit-box-shadow: inset 0 0 6px var(--transparent-color-02);
}

.section {
  width: 100%;
  min-height: 50vh;
  padding: 3.5rem;
}

.sec1 {
  position: relative;
  overflow: hidden;
}

.clip-path {
  background: linear-gradient(to right, var(--secondary-color-2), var(--secondary-color) 80%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 61%, 0 84%);
          clip-path: polygon(0 0, 100% 0, 100% 61%, 0 84%);
  top: 0;
  left: 0;
  right: 0;
  height: 90%;
  position: absolute;
}

.main-heading {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.main-item-sizer {
  width: 100%;
  position: relative;
}

.main-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding-bottom: 0;
  padding-right: 5rem;
  max-width: 100%;
}
.main-items .main-item-cont {
  width: 340px;
  height: 500px;
  background-color: var(--white-color-01);
  max-width: 100%;
  border-radius: 1.3rem;
  box-shadow: 1px 1px 2px var(--grey-2);
  border: 5px solid var(--white-color-01);
}
.main-items .main-item-cont .main-item-left {
  border: 10px solid var(--white-color-01);
  width: 100%;
  height: 400px;
  max-width: 100%;
  border-radius: 1.3rem;
  position: relative;
}
.main-items .main-item-cont .main-item-left img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-items .main-item-cont .profile-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.main-items .main-item-cont .profile-wrapper .left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
}
.main-items .main-item-cont .profile-wrapper .left-side .img-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.main-items .main-item-cont .profile-wrapper .left-side .img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-items .main-item-cont .profile-wrapper .left-side .text-box {
  padding-left: 0.3rem;
}
.main-items .main-item-cont .profile-wrapper .right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main-items .main-item-cont .profile-wrapper .right-side .figures-wrapper {
  padding-right: 1rem;
}
.main-items .main-item-right {
  margin-top: 0;
  width: 100%;
}
.main-items .main-item-right .text-wrapper {
  padding-bottom: 2rem;
  padding-right: 10rem;
  line-height: 1.6;
}
.main-items .main-item-right .text-wrapper h2 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  white-space: nowrap;
}
.main-items .main-item-right .right-items {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  padding-bottom: 3.3rem;
  padding-top: 2rem;
  gap: 0.5rem;
}
.main-items .main-item-right .right-items .right-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.main-items .main-item-right .right-items .right-item .img-cont {
  position: relative;
  display: flex;
  align-items: center;
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(1) {
  transform: translateX(0%);
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(2) {
  transform: translateX(80%);
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(3) {
  transform: translateX(140%);
}
.main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(4) {
  transform: translateX(200%);
}
.main-items .main-item-right .right-items .text-cont {
  padding-bottom: 1.7rem;
  display: flex;
  width: 320px;
  flex-direction: column;
  margin-top: -1.7rem;
  word-break: normal;
}
.main-items .main-item-right .right-items .text-cont .view-count {
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 0;
  position: relative;
  margin-bottom: 0.5rem;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
}
.main-items .main-item-right .right-items .text-cont .view-count::after {
  content: "";
  position: absolute;
  width: 10rem;
  height: 0.2rem;
  bottom: 0;
  left: 0;
  background-color: var(--btn-color);
  border-radius: 10rem;
}
.main-items .stats {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  padding-bottom: 2rem;
}
.main-items .stats .figure {
  font-size: 1.7rem;
  letter-spacing: 0.5px;
  font-weight: 800;
}
.main-items .stats .text {
  font-weight: 600;
  font-size: 0.77rem;
  text-transform: uppercase;
  text-align: center;
}

.sec2 {
  background-color: var(--white-color-01);
}

.about-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-right: 5rem;
  max-width: 100%;
  margin-top: -3.5rem;
}
.about-page .right-side {
  padding-right: 4rem;
}
.about-page .right-side h2 {
  font-size: 2.5rem;
  padding-bottom: 2rem;
}
.about-page .right-side p {
  line-height: 1.6;
}
.about-page .left-side .items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 2rem;
  padding-left: 4rem;
  gap: 5rem;
}
.about-page .left-side .items .item {
  position: relative;
}
.about-page .left-side .items .item .icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--btn-color);
  color: var(--white-color-01);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: -3.5rem;
}
.about-page .left-side .items .item .icon-wrapper .icon {
  font-size: 1.3rem;
}
.about-page .left-side .items .item h3 {
  margin: 0.5rem 0 1rem;
}
.about-page .left-side .items .item p {
  line-height: 1.6;
}

.communities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
}
.communities .cummunity {
  width: 350px;
  max-width: 100%;
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 2rem;
  box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
}
.communities .cummunity .img-cont {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}
.communities .cummunity .img-cont img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.communities .cummunity .overlay {
  transform: translateY(100%);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  background: linear-gradient(to right, var(--secondary-color-2), var(--secondary-color) 80%);
  transition: all 0.5s ease-in-out;
}
.communities .cummunity .overlay h2 {
  margin-bottom: 2rem;
  text-transform: capitalize;
  color: var(--btn-color);
}
.communities .cummunity .overlay .btn {
  width: 12rem;
  font-size: 1.2rem;
}
.communities .cummunity:hover .overlay {
  top: 0;
  bottom: 0;
  transform: translateY(0%);
}

.sec3 {
  min-height: 100vh;
  background: linear-gradient(to right top, var(--secondary-color-3), var(--secondary-color-4) 80%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.glass {
  width: 100%;
  min-height: 80vh;
  border-radius: 2rem;
  background-color: var(--white-color-02);
  z-index: 2;
  position: relative;
  box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
  -webkit-backdrop-filter: blur(0.2rem);
          backdrop-filter: blur(0.2rem);
}

.circle {
  background-color: var(--white-color-01);
  width: 15rem;
  height: 15rem;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(to right bottom, var(--white-color-01), var(--white-color-02));
}

.circle1 {
  top: 5%;
  right: 5%;
}

.circle2 {
  bottom: 0;
  left: 0;
}

.cryptoViewer-cont {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  border-radius: 2rem;
  background-color: var(--secondary-color-4);
  background: linear-gradient(to top right, var(--secondary-color-4), var(--white-color-2));
  -webkit-backdrop-filter: blur(0.2rem);
          backdrop-filter: blur(0.2rem);
  padding: 1rem;
}
.cryptoViewer-cont .name {
  text-align: center;
  width: 100%;
  font-size: 4rem;
  text-transform: capitalize;
}
.cryptoViewer-cont .crypto-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom-left-radius: 2rem;
  justify-content: center;
  border-bottom-right-radius: 2rem;
  padding: 0.5rem 2rem;
  background-color: var(--secondary-color-2);
  box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
  background: linear-gradient(to right bottom, var(--white-color-1), var(--white-color-2));
}
.cryptoViewer-cont .img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  position: relative;
}
.cryptoViewer-cont .img-cont .reload-cont {
  position: absolute;
  top: 0;
  right: -200%;
  transform: rotate(10deg);
  font-size: 2rem;
  cursor: pointer;
}
.cryptoViewer-cont .img-cont .reload-cont .reload {
  pointer-events: none;
}
.cryptoViewer-cont .img-cont .crypto-img {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 50%;
}
.cryptoViewer-cont .img-cont .crypto-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.cryptoViewer-cont .value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cryptoViewer-cont .value .price {
  font-size: 3.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  width: 100%;
}
.cryptoViewer-cont .type-cont {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cryptoViewer-cont .type-cont .type {
  font-size: 1.5rem;
  font-weight: bolder;
}

.random-cont {
  padding: 4rem 1rem;
}
.random-cont .tokens {
  display: grid;
  grid-template-columns: repeat(6, 150px);
  gap: 2rem;
  width: 100%;
  place-content: center;
}
.random-cont .tokens .token {
  width: 150px;
  box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
  cursor: pointer;
  border-radius: 1rem;
  overflow: hidden;
  background-color: var(--white-color-1);
}
.random-cont .tokens .token .img-cont .crypto-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.random-cont .tokens .token .img-cont .crypto-unit {
  width: 100%;
}
.random-cont .tokens .token .img-cont .crypto-unit p {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.spinner-cont {
  grid-column: span 6;
  align-items: center;
  justify-content: center;
}
.spinner-cont .spinner {
  font-size: 10rem;
  width: 100%;
  text-align: center;
  -webkit-animation: cursor-blink 700ms steps(4) infinite;
          animation: cursor-blink 700ms steps(4) infinite;
}

.btn {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0.7rem 1.5rem;
  text-align: center;
  border: none;
  border-radius: 1.5rem;
  background-color: var(--btn-color);
  color: var(--white-color-01);
  cursor: pointer;
  list-style: none;
  text-transform: capitalize;
}
.btn:hover {
  background-color: var(--white-color-02);
  color: var(--btn-color);
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--btn-color);
}

.btn-2 {
  background-color: var(--btn-color-2);
  width: 180px;
  padding: 0.8rem 1.5rem;
  text-align: center;
  font-weight: 600;
  border: 1px solid var(--btn-color-2);
  color: var(--white-color-01);
  border-radius: 1.5rem;
  text-transform: capitalize;
  cursor: pointer;
  list-style: none;
}
.btn-2:hover {
  background-color: var(--white-color-02);
  color: var(--btn-color-2);
  border: 1px solid var(--btn-color-2);
  transition: all 0.5s ease-in-out;
}

.blur-bg {
  background-color: var(--white-color-02);
  color: var(--btn-color);
  border: 1px solid var(--btn-color);
  font-weight: 600;
  padding: 0.8rem 1.5rem;
  border-radius: 1.5rem;
  width: 180px;
  text-transform: capitalize;
  cursor: pointer;
  list-style: none;
}
.blur-bg:hover {
  background-color: var(--btn-color);
  color: var(--white-color-01);
  transition: all 0.5s ease-in-out;
}

.connect-wallet {
  width: 180px;
  padding: 0.7rem 1rem;
  text-align: center;
}

.span-2 {
  grid-column: span 2;
}

.span-whole {
  grid-column: span 3;
}

.small-text {
  font-size: 0.8rem;
}

.bold {
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.text-box {
  line-height: 1.6;
  word-break: normal;
}

.grey {
  color: var(--grey-2);
}

.footer {
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  padding: 1rem;
  align-items: flex-end;
  color: var(--white-color-01);
  background-color: var(--btn-color);
}
.footer .footer-wrapper {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  text-align: center;
}
.footer .footer-wrapper .text-box {
  text-align: center;
}
.footer .footer-wrapper .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.footer .footer-wrapper .icon-wrapper .footer-icon {
  font-size: 1.5rem;
  cursor: pointer;
  border: 2px solid var(--white-color-01);
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.footer .footer-wrapper .icon-wrapper .footer-icon:hover, .footer .footer-wrapper .icon-wrapper .footer-icon :focus {
  background-color: var(--white-color-01);
  color: var(--btn-color);
  transform: translateY(-7px);
  transition: all 0.5s ease-in-out;
}

@-webkit-keyframes cursor-blink {
  0% {
    color: var(--grey-2);
    transform: rotate(360deg);
  }
}

@keyframes cursor-blink {
  0% {
    color: var(--grey-2);
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 64rem) {
  .header h1 {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .header .nav-bar {
    display: block;
  }
  .nav-body {
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 3rem;
    transform: translateX(100%) scaleX(0);
    background-color: var(--secondary-color-2);
  }
  .nav-body .nav {
    width: 100%;
  }
  .nav-body .nav .linkCont {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 2rem;
    gap: 0.5rem;
    text-shadow: 1px 2px 3px var(--white-color-01);
  }
  .nav-body .btn-cont {
    padding-top: 2rem;
    width: 100%;
    flex-direction: column;
    font-size: 1.1rem !important;
    justify-content: center;
  }
  .reset {
    transform: translateX(0) scaleX(1);
  }
  .header h1 {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .header .nav-bar {
    display: block;
  }
  .main-heading {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .main-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-right: 0;
    gap: 0.5rem;
    width: 100%;
    padding-bottom: 0;
  }
  .main-items .main-item-sizer {
    width: 100%;
    margin-left: -2rem;
  }
  .main-items .main-item-sizer .main-item-cont {
    width: 340px;
    height: 500px;
    background-color: var(--white-color-01);
    max-width: 100%;
    border-radius: 1.3rem;
    box-shadow: 1px 1px 2px var(--grey-2);
    border: 5px solid var(--white-color-01);
  }
  .main-items .main-item-sizer .main-item-cont .main-item-left {
    border: 10px solid var(--white-color-01);
    width: 100%;
    height: 400px;
    max-width: 100%;
    border-radius: 1.3rem;
    position: relative;
  }
  .main-items .main-item-sizer .main-item-cont .main-item-left img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.5rem;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .left-side .img-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .left-side .img-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .left-side .text-box {
    padding-left: 0.3rem;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main-items .main-item-sizer .main-item-cont .profile-wrapper .right-side .figures-wrapper {
    padding-right: 1rem;
  }
  .main-items .main-item-right {
    margin-top: -0.5rem;
    width: 100%;
  }
  .main-items .main-item-right .text-wrapper {
    padding-bottom: 2rem;
    padding-right: 2rem;
    line-height: 1.6;
  }
  .main-items .main-item-right .text-wrapper h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    white-space: nowrap;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .main-items .main-item-right .text-wrapper p {
    width: 100%;
  }
  .main-items .main-item-right .right-items .right-item .img-cont {
    position: relative;
    display: flex;
    align-items: center;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(1) {
    transform: translateX(0%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(2) {
    transform: translateX(80%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(3) {
    transform: translateX(140%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(4) {
    transform: translateX(200%);
  }
  .main-items .main-item-right .right-items .text-cont {
    padding-bottom: 1.7rem;
    display: flex;
    width: 100%;
    padding-right: 2rem;
    flex-direction: column;
    margin-top: -1.7rem;
    word-break: normal;
  }
  .main-items .main-item-right .right-items .text-cont .view-count {
    display: block;
    padding-bottom: 0.5rem;
    padding-left: 0;
    position: relative;
    margin-bottom: 0.5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
  }
  .main-items .main-item-right .right-items .text-cont .view-count::after {
    content: "";
    position: absolute;
    width: 10rem;
    height: 0.2rem;
    bottom: 0;
    left: 0;
    background-color: var(--btn-color);
    border-radius: 10rem;
  }
  .main-items .stats {
    display: grid;
    grid-template-columns: repeat(3, 130px);
    padding-bottom: 2rem;
  }
  .main-items .stats .figure {
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    font-weight: 800;
  }
  .main-items .stats .text {
    font-weight: 600;
    font-size: 0.77rem;
    text-transform: uppercase;
    text-align: center;
  }
  .sec2 {
    min-height: 50vh;
    background-color: var(--white-color-01);
  }
  .about-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    margin: 0 auto;
    max-width: 100%;
  }
  .about-page .right-side {
    padding-right: 1rem;
  }
  .about-page .right-side h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  .about-page .right-side p {
    line-height: 1.6;
  }
  .about-page .left-side .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;
  }
  .about-page .left-side .items .item {
    position: relative;
  }
  .about-page .left-side .items .item .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;
  }
  .about-page .left-side .items .item .icon-wrapper .icon {
    font-size: 1.3rem;
  }
  .about-page .left-side .items .item h3 {
    margin: 0.5rem 0 1rem;
  }
  .about-page .left-side .items .item p {
    line-height: 1.6;
  }
  .btn-2 {
    background-color: var(--btn-color-2);
    width: 180px;
    padding: 0.8rem 1rem;
    text-align: center;
    font-weight: 600;
  }
  .btn-2:hover {
    background-color: var(--white-color-02);
    color: var(--btn-color-2);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    border: 1px solid var(--btn-color-2);
  }
  .communities {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem;
    place-content: center;
  }
  .communities .cummunity {
    width: 100%;
    width: 300px;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
  }
  .communities .cummunity .img-cont {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
  }
  .communities .cummunity .img-cont img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .communities .cummunity .overlay {
    transform: translateY(100%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: linear-gradient(to right, var(--secondary-color-2), var(--secondary-color) 80%);
    transition: all 0.5s ease-in-out;
  }
  .communities .cummunity .overlay h2 {
    margin-bottom: 2rem;
    text-transform: capitalize;
    color: var(--btn-color);
  }
  .communities .cummunity .overlay .btn {
    width: 12rem;
    font-size: 1.2rem;
  }
  .communities .cummunity:hover .overlay {
    top: 0;
    bottom: 0;
    transform: translateY(0%);
  }
  .sec3 {
    min-height: 100vh;
    background: linear-gradient(to right top, var(--secondary-color-3), var(--secondary-color-4) 80%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .glass {
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position: relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    -webkit-backdrop-filter: blur(0.2rem);
            backdrop-filter: blur(0.2rem);
  }
  .circle {
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(to right bottom, var(--white-color-01), var(--white-color-02));
  }
  .circle1 {
    top: 5%;
    right: 5%;
  }
  .circle2 {
    bottom: 0;
    left: 0;
  }
  .cryptoViewer-cont {
    width: 100%;
    top: 15%;
    padding: 1rem;
  }
  .cryptoViewer-cont .name {
    text-align: center;
    width: 100%;
    font-size: 4rem;
  }
  .cryptoViewer-cont .crypto-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    background: linear-gradient(to right bottom, var(--white-color-1), var(--white-color-2));
  }
  .cryptoViewer-cont .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    position: relative;
  }
  .cryptoViewer-cont .img-cont .reload-cont {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor: pointer;
  }
  .cryptoViewer-cont .img-cont .reload-cont .reload {
    pointer-events: none;
  }
  .cryptoViewer-cont .img-cont .crypto-img {
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 50%;
  }
  .cryptoViewer-cont .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cryptoViewer-cont .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .cryptoViewer-cont .value .price {
    font-size: 3.5rem;
  }
  .cryptoViewer-cont .type-cont {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .cryptoViewer-cont .type-cont .type {
    font-size: 1rem;
  }
  .random-cont {
    padding: 4rem 1rem;
  }
  .random-cont .tokens {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 1rem;
    justify-content: center;
  }
  .random-cont .tokens .token {
    width: 150px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);
  }
  .random-cont .tokens .token .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .random-cont .tokens .token .img-cont .crypto-unit {
    width: 100%;
  }
  .random-cont .tokens .token .img-cont .crypto-unit p {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 57rem) {
  .header h1 {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .header .nav-bar {
    display: block;
  }
  .header .nav-body {
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 3rem;
    background-color: var(--secondary-color-2);
    transform: translateX(100%) scaleX(0);
  }
  .header .nav-body .nav {
    width: 100%;
  }
  .header .nav-body .nav .linkCont {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
  }
  .header .nav-body .btn-cont {
    padding-top: 2rem;
    width: 100%;
    flex-direction: column;
    font-size: 1.2rem;
    justify-content: center;
  }
  .header .reset {
    transform: translateX(0) scaleX(1);
  }
  .about-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    place-items: center;
    padding-right: 1.5rem;
    margin-top: -3rem;
    max-width: 100%;
  }
  .about-page .right-side {
    padding-right: 1rem;
  }
  .about-page .right-side h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  .about-page .right-side p {
    line-height: 1.6;
  }
  .about-page .left-side .items {
    display: grid;
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;
  }
  .about-page .left-side .items .item {
    position: relative;
  }
  .about-page .left-side .items .item .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;
  }
  .about-page .left-side .items .item .icon-wrapper .icon {
    font-size: 1.3rem;
  }
  .about-page .left-side .items .item h3 {
    margin: 0.5rem 0 1rem;
  }
  .about-page .left-side .items .item p {
    line-height: 1.6;
  }
  .sec3 {
    min-height: 100vh;
    background: linear-gradient(to right top, var(--secondary-color-3), var(--secondary-color-4) 80%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .glass {
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position: relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    -webkit-backdrop-filter: blur(0.2rem);
            backdrop-filter: blur(0.2rem);
  }
  .circle {
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(to right bottom, var(--white-color-01), var(--white-color-02));
  }
  .circle1 {
    top: 5%;
    right: 5%;
  }
  .circle2 {
    bottom: 0;
    left: 0;
  }
  .cryptoViewer-cont {
    width: 100%;
    padding: 1rem;
  }
  .cryptoViewer-cont .name {
    text-align: center;
    width: 100%;
    font-size: 4rem;
  }
  .cryptoViewer-cont .crypto-preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    background: linear-gradient(to right bottom, var(--white-color-1), var(--white-color-2));
  }
  .cryptoViewer-cont .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  .cryptoViewer-cont .img-cont .reload-cont {
    position: absolute;
    top: 0;
    outline: none;
    right: -200%;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor: pointer;
  }
  .cryptoViewer-cont .img-cont .reload-cont .reload {
    pointer-events: none;
    outline: none;
  }
  .cryptoViewer-cont .img-cont .crypto-img {
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 50%;
  }
  .cryptoViewer-cont .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cryptoViewer-cont .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .cryptoViewer-cont .value .price {
    font-size: 3.5rem;
    text-align: end;
    padding-right: 3rem;
  }
  .cryptoViewer-cont .type-cont {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .cryptoViewer-cont .type-cont .type {
    font-size: 1.5rem;
  }
  .random-cont {
    padding: 4rem 1rem;
  }
  .random-cont .tokens {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    row-gap: 2rem;
    width: 100%;
    justify-content: center;
  }
  .random-cont .tokens .token {
    width: 150px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);
  }
  .random-cont .tokens .token .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .random-cont .tokens .token .img-cont .crypto-unit {
    width: 100%;
  }
  .random-cont .tokens .token .img-cont .crypto-unit p {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 40rem) {
  .span-2 {
    grid-row: span 1;
  }
  .nav-body {
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 1.7rem !important;
    background-color: var(--secondary-color-2);
    transform: translateX(100%) scaleX(0);
  }
  .nav-body .nav {
    width: 100%;
  }
  .nav-body .nav .linkCont {
    margin-top: -1.5rem;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
  }
  .nav-body .btn-cont {
    padding-top: 1rem !important;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;
    justify-content: center;
  }
  .reset {
    transform: translateX(0) scaleX(1);
  }
  .header h1 {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .header .nav-bar {
    display: block;
  }
  .section {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .main-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 3rem;
    width: 100%;
  }
  .main-items .main-item-sizer {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-items .main-item-cont {
    width: 320px;
    height: 500px;
    background-color: var(--white-color-01);
    border-radius: 1.3rem;
    box-shadow: 1px 1px 2px var(--grey-2);
    border: 5px solid var(--white-color-01);
  }
  .main-items .main-item-cont .main-item-left {
    width: 100%;
    border: 10px solid var(--white-color-01);
    height: 400px;
    border-radius: 1.3rem;
  }
  .main-items .main-item-cont .main-item-left img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-cont .profile-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .main-items .main-item-cont .profile-wrapper .left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }
  .main-items .main-item-cont .profile-wrapper .left-side .img-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }
  .main-items .main-item-cont .profile-wrapper .left-side .img-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-cont .profile-wrapper .left-side .text-box {
    padding-left: 0.3rem;
  }
  .main-items .main-item-cont .profile-wrapper .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main-items .main-item-cont .profile-wrapper .right-side .figures-wrapper {
    padding-right: 1rem;
  }
  .main-items .main-item-right {
    grid-area: 1;
    align-self: center;
    justify-items: center;
    margin-top: -1.5rem;
    width: 100%;
  }
  .main-items .main-item-right .text-wrapper {
    padding-bottom: 2rem;
    padding-right: 0;
    line-height: 1.6;
    word-wrap: normal;
    position: relative;
  }
  .main-items .main-item-right .text-wrapper h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    white-space: wrap;
    width: 100%;
  }
  .main-items .main-item-right .text-wrapper p {
    width: 100%;
    word-wrap: normal;
    width: 100%;
  }
  .main-items .main-item-right .right-items {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 3rem;
  }
  .main-items .main-item-right .right-items .right-item .img-cont {
    position: relative;
    padding-bottom: 7rem;
    display: flex;
    align-items: center;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(1) {
    transform: translateX(0%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(2) {
    transform: translateX(80%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(3) {
    transform: translateX(140%);
  }
  .main-items .main-item-right .right-items .right-item .img-cont .img-wrapper:nth-of-type(4) {
    transform: translateX(200%);
  }
  .main-items .main-item-right .right-items .text-cont {
    padding-bottom: 1.7rem;
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 1rem;
    flex-direction: column;
    margin-top: -1.7rem;
    word-break: normal;
  }
  .main-items .main-item-right .right-items .text-cont .view-count {
    display: block;
    padding-bottom: 0.5rem;
    padding-left: 0;
    position: relative;
    margin-bottom: 0.5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
  }
  .main-items .main-item-right .right-items .text-cont .view-count::after {
    content: "";
    position: absolute;
    width: 10rem;
    height: 0.2rem;
    bottom: 0;
    left: 0;
    background-color: var(--btn-color);
    border-radius: 10rem;
  }
  .main-items .stats {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
  }
  .main-items .stats .figure {
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    font-weight: 800;
  }
  .main-items .stats .text {
    font-weight: 600;
    font-size: 0.77rem;
    text-transform: uppercase;
    text-align: center;
  }
  .sec2 {
    min-height: 50vh;
    background-color: var(--white-color-01);
  }
  .about-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    place-items: center;
    padding-right: 1.5rem;
    max-width: 100%;
  }
  .about-page .right-side {
    padding-right: 1rem;
  }
  .about-page .right-side h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  .about-page .right-side p {
    line-height: 1.6;
  }
  .about-page .left-side .items {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;
  }
  .about-page .left-side .items .item {
    position: relative;
  }
  .about-page .left-side .items .item .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;
  }
  .about-page .left-side .items .item .icon-wrapper .icon {
    font-size: 1.3rem;
  }
  .about-page .left-side .items .item h3 {
    margin: 0.5rem 0 1rem;
  }
  .about-page .left-side .items .item p {
    line-height: 1.6;
  }
  .communities {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
  .communities .cummunity {
    max-width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
  }
  .communities .cummunity .img-cont {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
  }
  .communities .cummunity .img-cont img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .communities .cummunity .overlay {
    transform: translateY(100%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: linear-gradient(to right, var(--secondary-color-2), var(--secondary-color) 80%);
    transition: all 0.5s ease-in-out;
  }
  .communities .cummunity .overlay h2 {
    margin-bottom: 2rem;
    text-transform: capitalize;
    color: var(--btn-color);
  }
  .communities .cummunity .overlay .btn {
    width: 12rem;
    font-size: 1.2rem;
  }
  .communities .cummunity:hover .overlay {
    top: 0;
    bottom: 0;
    transform: translateY(0%);
  }
  .sec3 {
    min-height: 100vh;
    background: linear-gradient(to right top, var(--secondary-color-3), var(--secondary-color-4) 80%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .glass {
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position: relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    -webkit-backdrop-filter: blur(0.2rem);
            backdrop-filter: blur(0.2rem);
  }
  .circle {
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(to right bottom, var(--white-color-01), var(--white-color-02));
  }
  .circle1 {
    top: 5%;
    right: 5%;
  }
  .circle2 {
    bottom: 0;
    left: 0;
  }
  .cryptoViewer-cont {
    width: 100%;
    top: 15%;
    padding: 1rem;
  }
  .cryptoViewer-cont .name {
    text-align: center;
    width: 100%;
    font-size: 3rem;
  }
  .cryptoViewer-cont .crypto-preview {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    background: linear-gradient(to right bottom, var(--white-color-1), var(--white-color-2));
  }
  .cryptoViewer-cont .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    position: relative;
  }
  .cryptoViewer-cont .img-cont .reload-cont {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor: pointer;
  }
  .cryptoViewer-cont .img-cont .reload-cont .reload {
    pointer-events: none;
  }
  .cryptoViewer-cont .img-cont .crypto-img {
    width: 130px;
    height: 130px;
    overflow: hidden;
    border-radius: 50%;
  }
  .cryptoViewer-cont .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cryptoViewer-cont .value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .cryptoViewer-cont .value .price {
    font-size: 2rem;
    text-align: end;
    padding-right: 0;
    width: 100%;
  }
  .cryptoViewer-cont .type-cont {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .cryptoViewer-cont .type-cont .type {
    font-size: 1rem;
    width: 100%;
  }
  .random-cont {
    padding: 4rem 1rem;
  }
  .random-cont .tokens {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
    width: 100%;
    justify-items: center;
  }
  .random-cont .tokens .token {
    width: 120px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2);
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);
  }
  .random-cont .tokens .token .img-cont .crypto-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .random-cont .tokens .token .img-cont .crypto-unit {
    width: 100%;
  }
  .random-cont .tokens .token .img-cont .crypto-unit p {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  .btn-2 {
    background-color: var(--btn-color-2);
    width: 180px;
    max-width: 100%;
    padding: 0.8rem 1.5rem;
    text-align: center;
    font-weight: 600;
  }
  .btn-2:hover {
    background-color: var(--white-color-02);
    color: var(--btn-color-2);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    border: 1px solid var(--btn-color-2);
  }
  .footer {
    width: 100%;
    height: 150px;
    position: relative;
    display: flex;
    padding: 1rem;
    align-items: flex-end;
    color: var(--white-color-01);
    background-color: var(--btn-color);
  }
  .footer .footer-wrapper {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    text-align: center;
  }
  .footer .footer-wrapper .text-box {
    text-align: center;
  }
  .footer .footer-wrapper .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  .footer .footer-wrapper .icon-wrapper .footer-icon {
    font-size: 1.5rem;
    cursor: pointer;
    border: 2px solid var(--white-color-01);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .footer .footer-wrapper .icon-wrapper .footer-icon:hover {
    background-color: var(--white-color-01);
    color: var(--btn-color);
    transform: translateY(-7px);
    transition: all 0.5s ease-in-out;
  }
  .spinner-cont {
    grid-column: span 6;
    align-items: center;
    justify-content: center;
  }
  .spinner-cont .spinner {
    font-size: 5rem;
    width: 100%;
    text-align: center;
    -webkit-animation: cursor-blink 700ms steps(4) infinite;
            animation: cursor-blink 700ms steps(4) infinite;
  }
}/*# sourceMappingURL=App.css.map */