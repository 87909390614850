    *,*::before,*::after{
    padding:0;
    margin: 0;
    box-sizing: border-box;
    }

    #root{
    padding:0;
    margin: 0;
    box-sizing: border-box;

    }

    :focus{
        outline: none;
    }

    .header{
    width: 100%;
    height: 6.3rem;
    display: flex;
    align-items: center;
    padding: .2rem 1rem;
    list-style: none;
    background: linear-gradient( to right, var(--secondary-color-2), var(--secondary-color));
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(5px);
    box-shadow: 1px 0 5px var(--grey-2), 1px 1px 5px 1px var(--grey-2) ;

    h1{
    font-size: 1.3rem;
    margin-left: 2.5rem;
    }

    .nav-bar{
    width: 50px;
    height: 50px;
    border-radius: .5rem;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 50%;
    display: none;
    transform: translateY(-50%);

    .bugger{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .5s ease-in-out;

    &::before{
    content: '';
    top: -300%;
    width: 2rem;
    height: .2rem;
    position: absolute;
    background-color: var(--white-color-01);
    transform: rotate(0deg);
    }

    &::after{
    content: '';
    width: 2rem;
    height: .2rem;
    top: 300%;
    position: absolute;
    transition: transform .5s ease-in-out;
    transform: rotate(0deg);
    background-color: var(--white-color-01);
    }

    div{
    width: 2rem;
    height: .2rem;
    background-color: var(--white-color-01);
    transition: transform .5s ease-in-out;
    }

    }

    }

    .nav-bar-close{
    width: 50px;
    height: 50px;
    border-radius: .5rem;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    cursor: pointer;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .5s ease-in-out;

    .bugger{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .5s ease-in-out;

    &::before{
    content: '';
    top: 15%;
    width: 2rem;
    height: .2rem;
    position: absolute;
    transform: rotate(45deg);
    transition: transform .5s ease-in-out;
    background-color: var(--white-color-01);
    }

    &::after{
    content: '';
    width: 2rem;
    height: .2rem;
    top: 300%;
    position: absolute;
    transition: transform .5s ease-in-out;
    transform: rotate(125deg);
    background-color: var(--white-color-01);
    }

    .center{
    width: 2rem;
    height: .2rem;
    background-color: var(--white-color-01);
    display:none;
    transition: transform .5s ease-in;
    }

    }

    }

    .nav-body{
    width:100%;
    display: flex;
    transition: transform .5s ease-in;
    }

    }

    .nav{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .linkCont{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    gap: 2rem;
    font-weight: 600;
    font-size: .9rem;
    }

    .linkCont li{
    cursor: pointer;
    position: relative;
    display: block;
    padding: .5rem 0;
    white-space: nowrap;

    &:hover::after,:focus::after{ 
    width: 100%;
    opacity: 1;
    transition: all .5s ease-in-out;
    }

    }

    .linkCont li::after,:focus::after {
    content: '';
    position: absolute;
    width:0;
    opacity: 0;
    transition: all .5s ease-in-out;
    background-color: var(--btn-color);
    height: .15rem;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    }

    .text-deco{
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    width: 100%;
    display: block;
    transition: all .5s ease-in-out;
    
    }

    .btn-cont{
    margin-right: auto;
    width: 400px; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    text-transform: capitalize;
    font-weight: 600;
    }

    .red{
    min-height: 100vh;
    }

    ::-webkit-scrollbar-thumb{
    background-color:var(--scroll-bar-color);
    border-radius: 1rem;
    border: 2px inset var(--transparent-color-01);
    border-bottom-left-radius:0;
    border-bottom-right-radius: 0;
    }

    ::-webkit-scrollbar{
    width: .5rem;
    height: .2rem;
    background-color:var(--secondary-color);
    }


    ::-webkit-scrollbar-track{
    margin-block-start: 6.3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: var(--white-color-01);
    box-shadow: inset 0 0 6px var(--transparent-color-02) ;
    -webkit-box-shadow:inset 0 0 6px var(--transparent-color-02);
    }

    .section{
    width: 100%;
    min-height:50vh;
    padding:3.5rem;
    }

    .sec1{
    position:relative;
    overflow: hidden;
    }

    .clip-path{
    background: linear-gradient( to right, var(--secondary-color-2), var(--secondary-color)80%);
    clip-path: polygon(0 0, 100% 0, 100% 61%, 0 84%);
    top: 0;
    left: 0;
    right: 0;
    height: 90%;
    position: absolute;
    }

    .main-heading{
    position: relative;
    width: 100%;
    height: fit-content;
    }

    .main-item-sizer{
    width: 100%;
    position: relative;
    }

    .main-items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding-bottom:0 ;
    padding-right: 5rem;
    max-width: 100%;

    .main-item-cont{
    width: 340px;
    height: 500px;
    background-color: var(--white-color-01);
    max-width: 100%;
    border-radius: 1.3rem;
    box-shadow: 1px 1px 2px var(--grey-2) ;
    border: 5px solid var(--white-color-01);

    .main-item-left{
    border: 10px solid var(--white-color-01);
    width: 100%;
    height: 400px;
    max-width: 100%;
    border-radius: 1.3rem;
    position: relative;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }

    .profile-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .left-side{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: .5rem;
    .img-wrapper{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position:relative;
    overflow: hidden;
    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }
    .text-box{
    padding-left: .3rem;
    }
    }

    .right-side{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .figures-wrapper{
    padding-right: 1rem;
    }

    }
    }
    }

    .main-item-right{
    margin-top: 0;
    width: 100%;
    .text-wrapper{
    padding-bottom: 2rem;
    padding-right: 10rem;
    line-height: 1.6;

    h2{
    font-size: 4rem;
    margin-bottom: 1.5rem;
    white-space: nowrap;
    }
    }

    .right-items{
    display: grid;
    grid-template-columns: repeat(2, 200px);
    padding-bottom: 3.3rem;
    padding-top: 2rem;
    gap:.5rem;

    .right-item{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .img-cont{
    position: relative;
    display: flex;
    align-items: center;
    .img-wrapper{
    width: 50px ;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }

    .img-wrapper:nth-of-type(1){
    transform: translateX(0%);
    }

    .img-wrapper:nth-of-type(2){
    transform: translateX(80%);
    }

    .img-wrapper:nth-of-type(3){
    transform: translateX(140%);
    }

    .img-wrapper:nth-of-type(4){
    transform: translateX(200%);
    }

    }

    }

    .text-cont{
    padding-bottom: 1.7rem;
    display: flex;
    width: 320px;
    flex-direction: column;
    margin-top: -1.7rem;
    word-break: normal;

    .view-count{
    display: block;
    padding-bottom: .5rem ;
    padding-left: 0;
    position: relative;
    margin-bottom: .5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;

    &::after{
    content: '';
    position: absolute;
    width:10rem;
    height: .2rem;
    bottom: 0;
    left: 0;
    background-color: var(--btn-color);
    border-radius: 10rem;
    }

    }

    }

    }

    }

    .stats{
    display: grid;
    grid-template-columns: repeat(3, 150px);
    padding-bottom: 2rem;

    .figure{
    font-size: 1.7rem;
    letter-spacing: .5px;
    font-weight: 800;
    }

    .text{
    font-weight: 600;
    font-size: .77rem;
    text-transform: uppercase;
    text-align: center;
    }

    }

    }
    .sec2{
    background-color: var(--white-color-01);
    }

    .about-page{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 5rem;
    max-width: 100%;
    margin-top: -3.5rem;

    .right-side{
    padding-right: 4rem;

    h2{
    font-size: 2.5rem;
    padding-bottom: 2rem;
    }

    p{
    line-height: 1.6;
    }

    }

    .left-side{

    .items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;

    .item{
    position: relative;

    .icon-wrapper{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;


    .icon{
    font-size: 1.3rem;
    }

    }

    h3{
    margin: .5rem 0 1rem;
    }

    p{
    line-height: 1.6;
    }

    }

    }

    }

    }


    //todo============================COMMUNITY==================================*/

    .communities{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 2rem;
    

    .cummunity{
    width: 350px;
    max-width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;

    .img-cont{
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    
    }

    .overlay{
    transform: translateY(100%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    background: linear-gradient( to right, var(--secondary-color-2), var(--secondary-color)80%);
    transition: all .5s ease-in-out;

    h2{
    margin-bottom: 2rem;
    text-transform: capitalize;
    color: var(--btn-color);
    }

    .btn{
    width: 12rem;
    font-size: 1.2rem;
    }

    }

    &:hover .overlay{
    top: 0;
    bottom: 0;

    transform: translateY(0%);
    }

    }

    }

    //todo============================CRYPTO PAGE===============================*/

    .sec3{
    min-height: 100vh;
    background: linear-gradient( to right top, var(--secondary-color-3), var(--secondary-color-4)80%);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .glass{
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position:relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    backdrop-filter: blur(.2rem);
    }

    .circle{
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient( to right bottom, var(--white-color-01), var(--white-color-02));
    }

    .circle1{
    top: 5%;
    right: 5%;
    }

    .circle2{
    bottom: 0;
    left: 0;
    }

    .cryptoViewer-cont{
    width: 100%;
    position: sticky;
    top: 10%;
    border-radius: 2rem;
    background-color: var(--secondary-color-4);
    background: linear-gradient( to top right, var(--secondary-color-4), var(--white-color-2));
    backdrop-filter: blur(.2rem);
    padding: 1rem;

    .name{
    text-align: center;
    width:100%;
    font-size: 4rem;
    text-transform: capitalize;
    }

    .crypto-preview{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom-left-radius: 2rem;
    justify-content: center;
    border-bottom-right-radius: 2rem;
    padding: .5rem 2rem;
    background-color: var(--secondary-color-2);
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    background: linear-gradient( to right bottom, var(--white-color-1), var(--white-color-2));
    }

    .img-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    position: relative;

    .reload-cont{
    position: absolute;
    top: 0;
    right: -200%;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor:pointer;

    .reload{
    pointer-events:none;
    }

    }

    .crypto-img{
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 50%;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }

    }


    //todo VALUE

    .value{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .price{
    font-size: 3.5rem;
    font-weight: bolder;
    text-transform: uppercase;
    width:100%;
    }

    }

    .type-cont{
    display:flex;
    align-items: flex-start;
    justify-content: center;

    .type{
    font-size: 1.5rem;
    font-weight: bolder;
    }

    }


    }

    .random-cont{
    padding: 4rem 1rem;

    .tokens{
    display: grid;
    grid-template-columns: repeat(6, 150px);
    gap: 2rem;
    width: 100%;
    place-content: center;

    .token{
    width: 150px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);

    .img-cont{

    .crypto-img{

    img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    }

    }
    .crypto-unit{
    width: 100%;

    p{
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    }

    }

    }

    }

    }

    }


    //todo INDEPENDENT COMPONENTS
    .spinner-cont{
    grid-column: span 6;
    align-items:center;
    justify-content: center;

    .spinner{
    font-size: 10rem;
    width: 100%;
    text-align: center;
    animation: cursor-blink 700ms steps(4) infinite;

    }

    }


    .btn{
    width: max-content;
    padding: .7rem 1.5rem;
    text-align: center;
    border:none;
    border-radius: 1.5rem;
    background-color:var(--btn-color);
    color:var(--white-color-01);
cursor: pointer;
    list-style: none;
    text-transform: capitalize;

    &:hover{
    background-color: var(--white-color-02);
    color: var(--btn-color);
    transition: all .5s ease-in-out;
    border: 1px solid var(--btn-color);
    }

    }

    .btn-2{
    background-color: var(--btn-color-2);
    width: 180px;
    padding: .8rem 1.5rem;
    text-align: center;
    font-weight: 600;
    border: 1px solid var(--btn-color-2);
    color:var(--white-color-01);
    border-radius: 1.5rem;
    text-transform: capitalize;
    cursor: pointer;
    list-style: none;

    &:hover{
    background-color: var(--white-color-02);
    color: var(--btn-color-2);
    border: 1px solid var(--btn-color-2);
    transition: all .5s ease-in-out;
    }

    }

    .blur-bg{
    background-color: var(--white-color-02);
    color: var(--btn-color);
    border: 1px solid var(--btn-color);
    font-weight: 600;
    padding: .8rem 1.5rem;
    border-radius: 1.5rem;
    width: 180px;
    text-transform: capitalize;
    cursor: pointer;
    list-style: none;

    &:hover{
    background-color: var(--btn-color);
    color: var(--white-color-01);
    transition: all .5s ease-in-out;
    }

    }

    .connect-wallet{
    width: 180px;
    padding: .7rem 1rem;
    text-align: center;
    }

    .span-2{
    grid-column: span 2;
    }

    .span-whole{
    grid-column: span 3;
    }

    .small-text{
    font-size: .8rem;
    }

    .bold{
    font-weight: bold;
    }

    .align-right{
    text-align: right;
    }

    .align-left{
    text-align: left;
    }

    .text-box{
    line-height: 1.6;
    word-break: normal;
    }

    .grey{
    color: var(--grey-2);
    }

    .footer{
    width:100%;
    height:200px;
    position: relative;
    display: flex;
    padding: 1rem;
    align-items: flex-end;
    color: var(--white-color-01);
    background-color: var(--btn-color);

    .footer-wrapper{
    width: 100%;
    height: max-content;
    text-align: center;

    .text-box{
    text-align: center;
    }

    .icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1rem;

    .footer-icon{
    font-size: 1.5rem;
    cursor: pointer;
    border: 2px solid var(--white-color-01);
    padding: .5rem;
    border-radius: .5rem;

    &:hover, :focus{
    background-color:var(--white-color-01);         
    color: var(--btn-color);
    transform: translateY(-7px);
    transition: all .5s ease-in-out;
    }

    }

    }

    }

    }

    @keyframes cursor-blink{
        
    0%{
    color: var(--grey-2);
    transform: rotate(360deg);
    }

    }


    @import './media'
