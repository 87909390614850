
    @media screen and  (max-width: 64rem){


    .header{
    h1{
    font-size: 1.3rem;
    margin-left: .5rem;
    }


    .nav-bar{
    display: block;
    }
    }

    .nav-body{
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position:absolute;
    top: 100%;
    right: 0;
    padding-top: 3rem;
    transform: translateX(100%) scaleX(0);
    // transition: transform 0.5s ease-in-out;
    background-color: var(--secondary-color-2);
    // background-color: orangered !important;

    .nav{ 
    width: 100%;
    // background-color: orange;

    .linkCont{
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 2rem;
    gap: .5rem;
    text-shadow: 1px 2px 3px var(--white-color-01);
    }
    }

    .btn-cont{
    padding-top: 2rem;
    width: 100%;
    // background-color: aqua;
    flex-direction: column;
    font-size: 1.1rem !important;
    justify-content: center;
    }

    }
    .reset{
    transform: translateX(0) scaleX(1);
    // transition: transform 1s ease-in-out;
    // animation: scaleAnim 1s forwards ease-in-out;

    }


    .header{
    h1{
    font-size: 1.3rem;
    margin-left: .5rem;
    }


    .nav-bar{
    display: block;
    }
    }

    .main-heading{

    position: relative;
    width: 100%;
    height: fit-content;
    }


    .main-items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-right:0;
    gap: .5rem;
    width: 100%;

    padding-bottom: 0;
    .main-item-sizer{
    width: 100%;
    margin-left: -2rem;
    .main-item-cont{
    width: 340px;
    height: 500px;
    background-color: var(--white-color-01);
    max-width: 100%;
    border-radius: 1.3rem;
    box-shadow: 1px 1px 2px var(--grey-2) ;


    border: 5px solid var(--white-color-01);

    .main-item-left{
    border: 10px solid var(--white-color-01);
    width: 100%;
    height: 400px;
    max-width: 100%;
    border-radius: 1.3rem;
    position: relative;


    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }


    }


    .profile-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .left-side{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: .5rem;
    .img-wrapper{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position:relative;
    overflow: hidden;
    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }
    .text-box{
    padding-left: .3rem;
    }
    }

    .right-side{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .figures-wrapper{
    padding-right: 1rem;
    }

    }
    }
    }
    }



    .main-item-right{

    margin-top: -.5rem;
    width: 100%;
    .text-wrapper{
    padding-bottom: 2rem;
    padding-right: 2rem;
    line-height: 1.6;

    h2{
    font-size: 3rem;
    margin-bottom: 1.5rem;
    white-space: nowrap;
    width: max-content;

    }
    p{
    width: 100%;
    }
    }

    .right-items{


    .right-item{



    .img-cont{
    position: relative;
    display: flex;
    align-items: center;
    .img-wrapper{
    width: 50px ;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }
    .img-wrapper:nth-of-type(1){
    transform: translateX(0%);

    }
    .img-wrapper:nth-of-type(2){
    transform: translateX(80%);

    }
    .img-wrapper:nth-of-type(3){
    transform: translateX(140%);


    }
    .img-wrapper:nth-of-type(4){
    transform: translateX(200%);
    }
    }
    }

    .text-cont{

    padding-bottom: 1.7rem;
    display: flex;
    width: 100%;
    padding-right: 2rem;
    flex-direction: column;
    margin-top: -1.7rem;
    word-break: normal;

    .view-count{
    display: block;
    padding-bottom: .5rem ;
    padding-left: 0;
    position: relative;
    margin-bottom: .5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
    &::after{
    content: '';
    position: absolute;
    width:10rem;
    height: .2rem;
    bottom: 0;
    left: 0;
    background-color: var(--btn-color);
    border-radius: 10rem;

    }




    }
    }
    }
    }

    .stats{
    display: grid;
    grid-template-columns: repeat(3, 130px);
    padding-bottom: 2rem;
    .figure{
    font-size: 1.7rem;
    letter-spacing: .5px;
    font-weight: 800;
    }
    .text{
    font-weight: 600;
    font-size: .77rem;
    text-transform: uppercase;
    text-align: center;
    }
    }
    }
    .sec2{
    min-height: 50vh;

    background-color: var(--white-color-01);
    }

    .about-page{
    display: grid;
    grid-template-columns:  1fr;
    gap: 3rem;
    margin:0 auto;
    max-width: 100%;

    .right-side{
    padding-right: 1rem;

    h2{
    font-size: 2rem;
    padding-bottom: 2rem;

    }
    p{
    line-height: 1.6;

    }
    }

    .left-side{

    .items{
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;


    .item{
    position: relative;

    .icon-wrapper{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;


    .icon{
    font-size: 1.3rem;
    }
    }

    h3{
    margin: .5rem 0 1rem;
    }

    p{
    line-height: 1.6;
    }
    }
    }
    }


    }

    .btn-2{
    background-color: var(--btn-color-2);
    width: 180px;
    padding: .8rem 1rem;
    text-align: center;
    font-weight: 600;

    &:hover{
    background-color: var(--white-color-02);
    color: var(--btn-color-2);
    backdrop-filter: blur(5px);
    border: 1px solid var(--btn-color-2);

    }
    }

    //todo============================COMMUNITY==================================*/

    .communities{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem;
    place-content: center;

    .cummunity{
    width: 100%;
    width: 300px;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;



    .img-cont{
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }

    .overlay{
    transform: translateY(100%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // gap: 2rem;
    position: absolute;
    background: linear-gradient( to right, var(--secondary-color-2), var(--secondary-color)80%);
    transition: all .5s ease-in-out;

    h2{
    margin-bottom: 2rem;
    text-transform: capitalize;
    color: var(--btn-color);
    }

    .btn{
    width: 12rem;
    font-size: 1.2rem;

    }
    }
    &:hover .overlay{
    top: 0;
    bottom: 0;

    transform: translateY(0%);

    }

    }
    }

    //todo============================CRYPTO PAGE===============================*/

    .sec3{
    min-height: 100vh;
    background: linear-gradient( to right top, var(--secondary-color-3), var(--secondary-color-4)80%);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .glass{
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position:relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    backdrop-filter: blur(.2rem);


    }

    .circle{
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient( to right bottom, var(--white-color-01), var(--white-color-02));

    }

    .circle1{
    top: 5%;
    right: 5%;
    }

    .circle2{
    bottom: 0;
    left: 0;
    }


    .cryptoViewer-cont{
    width: 100%;
    top: 15%;

    // background-color: orange;
    padding: 1rem;




    .name{
    text-align: center;
    width:100%;
    font-size: 4rem;
    }

    .crypto-preview{
    display:grid;
    grid-template-columns: repeat(3, 1fr) ;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);

    // padding: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    background: linear-gradient( to right bottom, var(--white-color-1), var(--white-color-2));





    }

    .img-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    position: relative;
    // background-color: blueviolet;

    .reload-cont{
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor:pointer;

    .reload{
    pointer-events:none;
    }

    }

    .crypto-img{
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 50%;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }

    }


    //todo VALUE

    .value{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // background-color: blue;
    .price{
    font-size: 3.5rem;



    }
    }

    .type-cont{
    display:flex;
    align-items: flex-start;
    justify-content: center;
    // width: 100%;
    // background-color: aqua;

    .type{
    font-size: 1rem;
    // padding-bottom: 1rem;

    }

    }


    }

    .random-cont{
    padding: 4rem 1rem;

    .tokens{
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 1rem;
    // width: 100%;
    justify-content: center;

    .token{
    width: 150px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    cursor: pointer;
    // padding-bottom: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);

    .img-cont{
    // width: 100px;
    // height: 100px;

    .crypto-img{

    img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    }

    }
    .crypto-unit{
    width: 100%;

    p{
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    }

    }

    }
    }
    }
    }




    }




    @media screen and (max-width: 57rem){


    .header{
    h1{
    font-size: 1.3rem;
    margin-left: .5rem;
    }


    .nav-bar{
    display: block;
    }
    .nav-body{
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position:absolute;
    top: 100%;
    right: 0;
    // transition: transform 0.5s ease-in-out;
    padding-top: 3rem;
    background-color: var(--secondary-color-2);
    // backdrop-filter: blur(5px);
    transform: translateX(100%) scaleX(0);

    .nav{ 
    width: 100%;

    .linkCont{
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
    }
    }

    .btn-cont{
    padding-top: 2rem;
    width: 100%;
    flex-direction: column;
    font-size: 1.2rem;
    justify-content: center;
    }
    }
    .reset{
    transform: translateX(0) scaleX(1);
    // transition: transform 1s ease-in-out;
    // animation: scaleAnim 1s forwards ease-in-out;

    }

    }

    .about-page{
    display: grid;
    grid-template-columns:  1fr;
    gap: 3rem;
    place-items: center;
    padding-right: 1.5rem;
    margin-top: -3rem;
    max-width: 100%;

    .right-side{
    padding-right: 1rem;

    h2{
    font-size: 2rem;
    padding-bottom: 2rem;

    }
    p{
    line-height: 1.6;

    }
    }

    .left-side{

    .items{
    display: grid;
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;


    .item{
    position: relative;

    .icon-wrapper{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;


    .icon{
    font-size: 1.3rem;
    }
    }

    h3{
    margin: .5rem 0 1rem;
    }

    p{
    line-height: 1.6;
    }
    }
    }
    }


    }

    //todo============================CRYPTO PAGE===============================*/

    .sec3{
    min-height: 100vh;
    background: linear-gradient( to right top, var(--secondary-color-3), var(--secondary-color-4)80%);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .glass{
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position:relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    backdrop-filter: blur(.2rem);


    }

    .circle{
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient( to right bottom, var(--white-color-01), var(--white-color-02));

    }

    .circle1{
    top: 5%;
    right: 5%;
    }

    .circle2{
    bottom: 0;
    left: 0;
    }


    .cryptoViewer-cont{
    width: 100%;
    // background-color: orange;
    padding: 1rem;

    .name{
    text-align: center;
    width:100%;
    font-size: 4rem;
    }

    .crypto-preview{
    display:grid;
    grid-template-columns: repeat(3, 1fr) ;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);

    // padding: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    background: linear-gradient( to right bottom, var(--white-color-1), var(--white-color-2));





    }

    .img-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    // background-color: blueviolet;
    .reload-cont{
    position: absolute;
    top: 0;
    outline: none;
    right: -200%;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor:pointer;

    .reload{
    pointer-events:none;
    outline: none;      

    }

    }

    .crypto-img{
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 50%;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }

    }


    //todo VALUE

    .value{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .price{
    font-size: 3.5rem;
    text-align: end;
    padding-right: 3rem;



    }
    }

    .type-cont{
    display:flex;
    align-items: flex-start;
    justify-content: center;
    // width: 100%;
    // background-color: aqua;

    .type{
    font-size: 1.5rem;
    // padding-bottom: 1rem;

    }

    }


    }

    .random-cont{
    padding: 4rem 1rem;

    .tokens{
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    column-gap: 1rem;
    row-gap: 2rem;
    width: 100%;
    justify-content: center;

    .token{
    width: 150px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);

    .img-cont{
    // width: 100px;
    // height: 100px;

    .crypto-img{

    img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    }

    }
    .crypto-unit{
    width: 100%;

    p{
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    }

    }

    }
    }
    }
    }





    }



    @media screen and (max-width: 40rem){

    .span-2{
    grid-row: span 1;
    }

    .nav-body{
    width: 300px !important;
    height: 100vh;
    flex-direction: column;
    position:absolute;
    top: 100%;
    right: 0;
    padding-top: 1.7rem !important;
    background-color: var(--secondary-color-2);
    // backdrop-filter: blur(5px);
    // transition: transform 0.5s ease-in-out;
    transform: translateX(100%) scaleX(0);

    .nav{ 
    width: 100%;

    .linkCont{
        margin-top: -1.5rem;
    flex-direction: column;
    width: 100%;
    // color:var(--white-color-01);
    justify-content: center;
    font-size: 1.2rem;
    }
    }

    .btn-cont{
    padding-top: 1rem !important;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;
    justify-content: center;
    }
    }
    .reset{
    transform: translateX(0) scaleX(1);
    // transition: transform 1s ease-in-out;
    // animation: scaleAnim 1s forwards ease-in-out;

    }


    .header{
    h1{
    font-size: 1.3rem;
    margin-left: .5rem;
    }


    .nav-bar{
    display: block;
    }
    }


    .section{
    padding-right:1rem;
    padding-left:1rem;

    }


    .main-items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap:3rem;

    width: 100%;

    .main-item-sizer{
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;


    }


    .main-item-cont{

    width: 320px;
    height: 500px;


    background-color: var(--white-color-01);
    border-radius: 1.3rem;
    box-shadow: 1px 1px 2px var(--grey-2) ;


    border: 5px solid var(--white-color-01);

    .main-item-left{
    width: 100%;
    border: 10px solid var(--white-color-01);
    height: 400px;
    border-radius: 1.3rem;


    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }


    }


    .profile-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .left-side{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5rem;
    .img-wrapper{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position:relative;
    overflow: hidden;
    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

    }
    .text-box{
    padding-left: .3rem;
    }
    }

    .right-side{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .figures-wrapper{
    padding-right: 1rem;
    }

    }
    }
    }

    .main-item-right{
    grid-area: 1;
    align-self: center;
    justify-items: center;
    margin-top: -1.5rem;
    width: 100%;
    .text-wrapper{
    padding-bottom: 2rem;
    padding-right:0;
    line-height: 1.6;
    // width: 100%;
    word-wrap: normal;
    position: relative;

    h2{
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    white-space: wrap;
    width: 100%;

    }
    p{
    width: 100%;
    word-wrap: normal;
    width: 100%;


    }
    }

    .right-items{
    grid-template-columns: 1fr;
    width: 100%;
    gap: 3rem;
    .right-item{




    .img-cont{
    position: relative;
    padding-bottom: 7rem;
    display: flex;
    align-items: center;
    .img-wrapper{
    width: 50px ;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }
    .img-wrapper:nth-of-type(1){
    transform: translateX(0%);

    }
    .img-wrapper:nth-of-type(2){
    transform: translateX(80%);

    }
    .img-wrapper:nth-of-type(3){
    transform: translateX(140%);


    }
    .img-wrapper:nth-of-type(4){
    transform: translateX(200%);
    }
    }
    }

    .text-cont{
    padding-bottom: 1.7rem;
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 1rem;
    flex-direction: column;
    margin-top: -1.7rem;
    word-break: normal;

    .view-count{
    display: block;
    padding-bottom: .5rem ;
    padding-left: 0;
    position: relative;
    margin-bottom: .5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
    &::after{
    content: '';
    position: absolute;
    width:10rem;
    height: .2rem;
    bottom: 0;
    left: 0;
    background-color: var(--btn-color);
    border-radius: 10rem;

    }




    }
    }
    }
    }

    .stats{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    .figure{
    font-size: 1.7rem;
    letter-spacing: .5px;
    font-weight: 800;
    }
    .text{
    font-weight: 600;
    font-size: .77rem;
    // color: var(--grey-5);
    text-transform: uppercase;
    text-align: center;
    }
    }
    }
    .sec2{
    min-height: 50vh;

    background-color: var(--white-color-01);
    }

    .about-page{
    display: grid;
    grid-template-columns:  1fr;
    gap: 3rem;
    place-items: center;
    padding-right: 1.5rem;
    max-width: 100%;

    .right-side{
    padding-right: 1rem;

    h2{
    font-size: 2rem;
    padding-bottom: 2rem;

    }
    p{
    line-height: 1.6;

    }
    }

    .left-side{

    .items{
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
    padding-left: 4rem;
    gap: 5rem;


    .item{
    position: relative;

    .icon-wrapper{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--btn-color);
    color: var(--white-color-01);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -3.5rem;


    .icon{
    font-size: 1.3rem;
    }
    }

    h3{
    margin: .5rem 0 1rem;
    }

    p{
    line-height: 1.6;
    }
    }
    }
    }




    }

    //todo============================COMMUNITY==================================*/

    .communities{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;

    .cummunity{
    max-width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;



    .img-cont{
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }

    .overlay{
    transform: translateY(100%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // gap: 2rem;
    position: absolute;
    background: linear-gradient( to right, var(--secondary-color-2), var(--secondary-color)80%);
    transition: all .5s ease-in-out;

    h2{
    margin-bottom: 2rem;
    text-transform: capitalize;
    color: var(--btn-color);
    }

    .btn{
    width: 12rem;
    font-size: 1.2rem;

    }
    }
    &:hover .overlay{
    top: 0;
    bottom: 0;

    transform: translateY(0%);

    }

    }
    }
    //todo============================CRYPTO PAGE===============================*/

    .sec3{
    min-height: 100vh;
    background: linear-gradient( to right top, var(--secondary-color-3), var(--secondary-color-4)80%);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .glass{
    width: 100%;
    min-height: 80vh;
    border-radius: 2rem;
    background-color: var(--white-color-02);
    z-index: 2;
    position:relative;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    backdrop-filter: blur(.2rem);


    }

    .circle{
    background-color: var(--white-color-01);
    width: 15rem;
    height: 15rem;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient( to right bottom, var(--white-color-01), var(--white-color-02));

    }

    .circle1{
    top: 5%;
    right: 5%;
    }

    .circle2{
    bottom: 0;
    left: 0;
    }


    .cryptoViewer-cont{
    width: 100%;
    // position: sticky;
    top: 15%;

    // background-color: orange;
    padding: 1rem;

    .name{
    text-align: center;
    width:100%;
    font-size: 3rem;
    }

    .crypto-preview{
    display:flex;
    flex-direction: column;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--white-color-01);

    // padding: 2rem;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    background: linear-gradient( to right bottom, var(--white-color-1), var(--white-color-2));





    }

    .img-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
    position: relative;
    // background-color: blueviolet;
    .reload-cont{
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(10deg);
    font-size: 2rem;
    cursor:pointer;

    .reload{
    pointer-events:none;
    }

    }

    .crypto-img{
    width: 130px;
    height: 130px;
    overflow: hidden;
    border-radius: 50%;

    img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
    }

    }


    //todo VALUE

    .value{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .price{
    font-size: 2rem;
    text-align: end;
    padding-right: 0;
    width: 100%;



    }
    }

    .type-cont{
    display:flex;
    align-items: flex-start;
    justify-content: center;
    // width: 100%;
    // background-color: aqua;

    .type{
    font-size: 1rem;
    width: 100%;
    // padding-bottom: 1rem;

    }

    }


    }

    .random-cont{
    padding: 4rem 1rem;

    .tokens{
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
    width: 100%;
    justify-items: center;


    .token{
    width: 120px;
    box-shadow: 1px 1px 5px var(--grey-2), 1px 1px 0 0 var(--grey-2) ;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background-color: var(--white-color-1);

    .img-cont{
    // width: 100px;
    // height: 100px;

    .crypto-img{

    img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    }

    }
    .crypto-unit{
    width: 100%;

    p{
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    }

    }

    }
    }
    }
    }



    .btn-2{
    background-color: var(--btn-color-2);
    width: 180px;
    max-width: 100%;
    padding: .8rem 1.5rem;
    text-align: center;
    font-weight: 600;

    &:hover{
    background-color: var(--white-color-02);
    color: var(--btn-color-2);
    backdrop-filter: blur(5px);
    border: 1px solid var(--btn-color-2);

    }
    }

    .footer{
    width:100%;
    height:150px;
    position: relative;
    display: flex;
    padding: 1rem;
    // flex-direction: column;
    align-items: flex-end;
    color: var(--white-color-01);
    background-color: var(--btn-color);

    .footer-wrapper{
    width: 100%;
    height: max-content;
    // background-color: aqua;
    text-align: center;


    .text-box{
    text-align: center;
    }

    .icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1rem;

    .footer-icon{
    font-size: 1.5rem;
    cursor: pointer;
    border: 2px solid var(--white-color-01);
    padding: .5rem;
    border-radius: .5rem;

    &:hover{
    background-color:var(--white-color-01);
    color: var(--btn-color);
    transform: translateY(-7px);
    transition: all .5s ease-in-out;
    }

    }
    }


    }

    }


    .spinner-cont{
    grid-column: span 6;
    align-items:center;
    justify-content: center;
    .spinner{
    font-size: 5rem;
    width: 100%;
    text-align: center;
    animation: cursor-blink 700ms steps(4) infinite;

    }

    }


    }


